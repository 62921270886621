import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import menu from './modules/menu.js';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'',  //初始化token
    base:{},
  },
  mutations: {
    //存储token方法
    //设置token等于外部传递进来的值
    setToken(state, token) {
      state.token = token
      localStorage.token = token //同步存储token至localStorage
    },
    setBase(state,info){
		state.base = info
    }
  },
  modules: {
  	menu,
  },
  getters : {
	  
    //获取token方法
    //判断是否有token,如果没有重新赋值，返回给state的token
    getToken(state) {
      if (!state.token) {
        state.token = localStorage.getItem('token')
      }
      return state.token
    },
    getBase(state){
      return state.base
    }
  },
  actions: {

  },
  plugins: [createPersistedState()],
})

