<template>
	<div id="app">
		<div v-title data-title="aleopool"></div>
		<router-view v-if="isShow"></router-view>
	</div>
	
</template>

<script>
export default {
	//刷新
	name: 'App',
	data () {
	  return {
	// 控制router-view的隐藏与展示
	    isShow: true
	  }
	},
	 // 提供可注入子组件属性
	provide () {
	  return {
	    reload: this.reload
	  }
	},
	//
	created() {
		//在页面加载时读取sessionStorage里的状态信息
		if (sessionStorage.getItem("store") ) {
			this.$store.replaceState(Object.assign({},this.$store.state,JSON.parse(sessionStorage.getItem("store"))))
		}
       //在页面刷新时将vuex里的信息保存到sessionStorage里
		window.addEventListener("beforeunload",()=>{
			sessionStorage.setItem("store",JSON.stringify(this.$store.state))
		})
	},
	methods: {
		 isMobile() {
		 	 let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		 	 return flag;
		 },
		 //刷新
		 reload () {
			// 先隐藏
			  this.isShow = false
			  // $nextTick() 将回调延迟到下次 DOM 更新循环之后执行
			  this.$nextTick(() => {
			    this.isShow = true
			  })
		},
	},
	beforeMount() {
		var clientWidth = document.documentElement.clientWidth
	    if (this.isMobile() || clientWidth < 1237) {
	     window.location.href='https://mobile.aleopool.top/';
	    } 
	  }
};
</script>

<style lang="less">
//公共类
@import "./assets/css/reset.css";
</style>