require('./plugins/axios')
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//axios
import '@/plugins/axios'
import VueClipboard from 'vue-clipboard2'
//cookie
import {setCookie,getCookie,delCookie} from '@/assets/js/cookie.js'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueClipboard)
//cookie
Vue.prototype.$cookieStore = {
	setCookie,
	getCookie,
	delCookie
}
//title
Vue.directive('title', {
　　inserted: function (el, binding) {
　　　　document.title = el.dataset.title
　　}
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')