import Vue from 'vue'
import VueRouter from 'vue-router'
import { Message } from 'element-ui';


Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
	path: '/login',
	name: 'Login',
	alias: "/",
	component: () => import("../views/admin/login.vue")
},
{
	path: '/register',
	name: 'Register',
	component: () => import('../views/admin/register.vue')
},
{
	path:"/home",
	name:'Home',
	component: () => import('../components/Layout.vue'),
	children:[
	{
		path: "index",
		name: "Index",
		component: () => import('../views/home/index.vue'),
	}]
},
{
	path:"/personal",
	name:'Personal',
	component: () => import('../components/Layout.vue'),
	children:[
		{
		path: "info",
		name: "info",
		component: () => import('../views/personal/info.vue'),
	},
	{
		path: "authentication",
		name: "Authentication",
		component: () => import('../views/personal/authentication.vue'),
	},
	{
		path: "password",
		name: "Password",
		component: () => import('../views/personal/password.vue'),
	},]
},
{
	path:"/asset",
	name:'Asset',
	component: () => import('../components/Layout.vue'),
	children:[
		{
		path: "asset",
		name: "Asset",
		component: () => import('../views/asset/asset.vue'),
	},
	{
		path: "mine",
		name: "Mine",
		props: true,
		component: () => import('../views/asset/mine.vue'),
	},]
},
{
	path:"/server",
	name:'Server',
	component: () => import('../components/Layout.vue'),
	children:[
		{
		path: "server",
		name: "Server",
		component: () => import('../views/server/server.vue'),
	},{
		path: "purchase",
		name: "Purchase",
		component: () => import('../views/server/purchase.vue'),
	},{
		path: "second",
		name: "Second",
		component: () => import('../views/server/second.vue'),
	},{
		path: "detail",
		name: "serveDetail",
		component: () => import('../views/server/detail.vue'),
	}]
},
{
	path:"/distribution",
	name:'distribution',
	component: () => import('../components/Layout.vue'),
	children:[
		{
		path: "distribution",
		name: "Distribution",
		component: () => import('../views/distribution/distribution.vue'),
	},
	{
		path: "detail",
		name: "distributionDetail",
		component: () => import('../views/distribution/detail.vue'),
	},{
		path: "team",
		name: "team",
		component: () => import('../views/distribution/team.vue'),
	},{
		path: "ppsteam",
		name: "ppsteam",
		component: () => import('../views/distribution/ppsteam.vue'),
	},]
},
{
	path: '*',
	name: '404',
	component: () => import('../views/admin/404.vue'),
},

]
const router = new VueRouter({
  routes,
  mode: 'history'
})
//
router.beforeEach((to, from, next) => {
    function checkBrowser() {
        let ua = navigator.userAgent.toLocaleLowerCase();
        let browserType = "", browserVersion = "";
        if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
            browserType = "IE";
            //检测ie11.0
            browserVersion = ua.match(/msie ([\d.]+)/) != null ? ua.match(/msie ([\d.]+)/)[1] : ua.match(/rv:([\d.]+)/)[1];
        } else if (ua.match(/firefox/) != null) {
            browserType = "Firefox"; // 火狐
        } else if (ua.match(/opera/) != null) {
            browserType = "Opera"; // 欧朋
        } else if (ua.match(/chrome/) != null) {
            browserType = "Chrome"; // 谷歌
        } else if (ua.match(/safari/) != null) {
            browserType = "Safari"; // Safari
        }
        var arr = new Array(browserType, browserVersion);
        return arr;
    }
    let browserArr = checkBrowser();
    if (browserArr[0] == 'IE' && (1 * browserArr[1]) < 11 ) {
        Message.error('请在IE11浏览器或则其他非IE浏览器进行登录!')
    } else {
        next();
    }
})



export default router
