export default{
	namespaced:true,
	state:{
		isCollapse: false,
	},
	mutations:{
		toggleMenu(state) {
			state.isCollapse = !state.isCollapse;
		},
	},
	getters:{
		
	},
	actions:{
		
	}
}